<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '650px' }"
    header="CONFIRMAR VENTA"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12 text-center">
          <i
            class="pi pi-check-circle"
            style="font-size: 1.5rem; color: green"
          ></i>
          <span style="font-size: 1.2rem; font-weight: bold">{{
            mensajeConfirmacion
          }}</span>
        </div>
        <div class="field col-12 md:col-12">
          <DataTable
            v-if="productos.length > 0"
            :value="productos"
            class="p-datatable-sm"
            responsive="true"
            :rowhover="true"
            stripedRows
            showGridlines
            selectionMode="single click"
          >
            <Column header="#">
              <template #body="slotProps">
                {{ 1 + slotProps.index }}
              </template>
            </Column>
            <Column
              field="nombre_producto"
              header="DESCRIPCIÓN/PRODUCTO"
              style="font-size: 1.2em; font-weight: bold"
            />
            <Column
              field="cantidad_venta"
              header="CANT.KG"
              style="font-size: 1.2em; text-align: right; width: 50px"
             v-if="cantidad_venta>0"
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(slotProps.data.cantidad_venta)
                }}
              </template>
            </Column>
            <Column
              field="unidades"
              header="UNIDADES"
              style="font-size: 1.2em; text-align: right; width: 50px"
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(slotProps.data.unidades)
                }}
              </template>
            </Column>
            <Column
              field="ultimo_precio"
              header="PRECIO"
              style="font-size: 1.2em"
              v-if="cantidad_venta>0"

            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(slotProps.data.ultimo_precio)
                }}
              </template>
            </Column>
            <Column
              field="total"
              header="TOTAL"
              style="font-size: 1.2em; text-align: right"
              v-if="cantidad_venta>0"
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(
                    slotProps.data.ultimo_precio *
                      slotProps.data.cantidad_venta -
                      slotProps.data.descuento_neto
                  )
                }}
              </template>
            </Column>
            <ColumnGroup type="footer" v-if="cantidad_venta>0">
              <Row>
                <Column
                  footer="TOTAL VENTA"
                  :colspan="5"
                  footerStyle="text-align:right ; font-weight: bold; font-size: 1.2em"
                />
                <Column
                  class="text-right"
                  style="font-size: 1.2em"
                  :footer="convertirNumeroGermanicFormat(total_venta)"
                />
              </Row>
            </ColumnGroup>
          </DataTable>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="p-d-flex p-jc-between">
        <Button
          label="CANCELAR"
          icon="pi pi-times"
          class="p-button-danger"
          @click="ocultarDialog"
        />
        <Button
          label="CONFIRMAR"
          icon="pi pi-check"
          class="p-button-info"
          :loading="cargando"
          @click="confirmarVenta"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
export default {
  emits: ["closeModal", "confirmarVenta"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    productos: {
      type: Array,
      required: true,
    },
    mensajeConfirmacion: {
      type: String,
      required: true,
    },
    total_venta: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      cargando: false,
    };
  },
  methods: {
    confirmarVenta() {
      this.cargando = true;
      this.$emit("confirmarVenta");
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    ocultarDialog() {
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.cargando = false;
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
  },
};
</script>

<style scoped>
.productos-table {
  width: 100%;
  border-collapse: collapse;
}

.productos-table th,
.productos-table td {
  border: 1px solid #ddd;
  padding: 4px;
}

.productos-table th {
  background-color: #f2f2f2;
  text-align: left;
}
</style>
